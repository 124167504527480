<template>
    <div class="page">
        <div class="school">年级：六年级</div>
        <div class="indexList">
            <van-index-bar ref="vanIndexBar"  sticky :index-list="indexList" :index="selectKey">
                
                <template v-for="(item, index) in classList">
                    <van-index-anchor :index="item.name" >
                        <div class="title" @click="showSelectKey = true">年级:&nbsp;&nbsp;{{item.name}} <van-icon name="arrow-down"></van-icon></div>
                    </van-index-anchor>
                    
                    <div class="goods-list"  :style="{minHeight: index === classList.length - 1 ? '90vh' :'' }">
                        <div class="goods-item" v-for="good in item.children">
                            <div class="name">{{good.name}}</div>
                            <div class="num">数量:<span>&nbsp;&nbsp;{{good.value}}</span></div>
                        </div>
                        <div class="buttonGroup">
                            <van-button type="primary" plain color="#FF553F" @click="goDetail">班级校服订购明细</van-button>
                        </div>
                    </div>
                
                </template>
            </van-index-bar>
        </div>
        
        <van-popup v-model="showSelectKey" round position="bottom">
            <van-picker
                show-toolbar
                :columns="indexList"
                @cancel="showSelectKey = false"
                @confirm="onConfirm"
            />
        </van-popup>
    </div>
</template>

<script>
let classList = [{
    name: '六1班',
    children: [{
        name: '长郡雨外洪塘学校小学部新生班服（二件)男女同款',
        value: 1000
    },{
        name: '长郡雨外洪塘学校小学部冬裤',
        value: 999
    },{
        name: '长郡雨外洪塘学校小学部冬季棉衣',
        value: 333
    }]
}, {
    name: '六2班',
    children: [{
        name: '长郡雨外洪塘学校小学部新生班服（二件)男女同款',
        value: 1000
    },{
        name: '长郡雨外洪塘学校小学部冬裤',
        value: 999
    },{
        name: '长郡雨外洪塘学校小学部冬季棉衣',
        value: 333
    }]
}, {
    name: '六3班',
    children: [{
        name: '长郡雨外洪塘学校小学部新生班服（二件)男女同款',
        value: 1000
    },{
        name: '长郡雨外洪塘学校小学部冬裤',
        value: 999
    },{
        name: '长郡雨外洪塘学校小学部冬季棉衣',
        value: 333
    }]
}, {
    name: '六4班',
    children: [{
        name: '长郡雨外洪塘学校小学部新生班服（二件)男女同款',
        value: 1000
    },{
        name: '长郡雨外洪塘学校小学部冬裤',
        value: 999
    },{
        name: '长郡雨外洪塘学校小学部冬季棉衣',
        value: 333
    }]
}, {
    name: '六5班',
    children: [{
        name: '长郡雨外洪塘学校小学部新生班服（二件)男女同款',
        value: 1000
    },{
        name: '长郡雨外洪塘学校小学部冬裤',
        value: 999
    },{
        name: '长郡雨外洪塘学校小学部冬季棉衣',
        value: 333
    }]
}, {
    name: '六6班',
    children: [{
        name: '长郡雨外洪塘学校小学部新生班服（二件)男女同款',
        value: 1000
    },{
        name: '长郡雨外洪塘学校小学部冬裤',
        value: 999
    },{
        name: '长郡雨外洪塘学校小学部冬季棉衣',
        value: 333
    }]
}]
export default {
    name: "class",
    data() {
        return {
            indexList: classList.map(e => e.name),
            classList,
            
            showSelectKey: false,
            selectKey: ''
        }
    },
    
    methods: {
        onConfirm(val) {
            this.selectKey = val
            this.$refs.vanIndexBar.scrollTo(val)
            this.showSelectKey = false
        },
        goDetail() {
            this.$router.push('/management/detail')
        }
    },
    mounted() {
    }
}
</script>

<style scoped lang="less">
.page {
    height: 100vh;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
    
    .indexList {
        flex: 1;
        overflow-y: auto;
    }
}
.school {
    padding: 0 29px;
    height: 38px;
    font-size: 12px;
    font-family: Source Han Sans CN;
    font-weight: 500;
    color: #212121;
    line-height: 38px;
    background-color: #fff;
    position: relative;
    z-index: 2;
}
.title {
    height: 35px;
    padding: 0 29px;
    
    font-size: 12px;
    font-weight: 500;
    color: #212121;
    line-height: 35px;
}
.goods-list {
    padding: 0 28px;
    
    .goods-item {
        padding: 10px 0;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        border-bottom: 1px solid #EEEDEC;
        .name {
            font-size: 12px;
            color: #212121;
            line-height: 20px;
            width: 220px;
            word-break: normal;
        }
        
        .num {
            font-size: 10px;
            color: #999999;
            line-height: 20px;
            span {color: #f00;}
        }
    }
}

.buttonGroup {
    padding: 15px 0;
    background-color: #fff;
    .van-button {
        width: 100%;
    }
    
}
::v-deep .van-index-anchor {
    padding: 0;
    background-color: #f4f4f4;
}
::v-deep .van-index-bar__sidebar {
    display: none;
}
</style>
